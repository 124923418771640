<template>
  <div class="col p-0 d-flex flex-column align-items-center justify-content-center">
    <b-card class="col col-md-10 col-lg-8 col-xl-6 mt-1 text-justify p-0">
      <TextBlock title="Borne Biodiversité"/>
      <p>
        Cette borne biodiversité est en phase de développement.
        Elle a été pensée pour accompagner les visteurs et visiteuses des jardineries dans leur démarche de protection de la biodiversité.
        Vous pouvez y : consulter des informations sur les plantes, évaluer vos pratiques, favoriser des méthodes de gestion agro-écologiques, obtenir des conseils ...

        N'hésitez pas à nous faire des retours :)
      </p>
      <TextBlock title="Jaya"/>
      <p>
        <strong>JAYA</strong>
        Nous avons créée JAYA en 2020, avec pour envie commune d'encourager le premier pas vers le jardinage. Au fil de nos rencontres, nous avons compris que les outils que nous proposons devaient aussi être des vecteurs de sensibilisation à la préservation de la biodiversité.

        Depuis, nous sommes engagés dans l’entrepreneuriat social et solidaire et en adoptons les règles. En 2023, nous avons aussi souhaité proposé des ateliers - supports moins numériques, plus proche des gens.
      </p>
    </b-card>
  </div>
</template>


<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {BCard} from "bootstrap-vue"

export default {
  name: 'about-us',
  components: {
    JayaTitle,
    TextBlock,
    BCard
  },
  mounted() {
    this.$store.commit("app/SET_TITLE", "À-propos")
  }
}
</script>

<style lang="scss">
</style>
